import { API, i18n } from "@project/shared";

export const getUserInfo = async ({ queryKey }) => {
  const { data } = await API.post(`/get-user-details`, {
    uuid: queryKey[1],
    role: "company",
  });
  return { name: data.name || i18n.t("No Name"), ...data };
};

export const deleteUser = (id) => {
  return API.delete(`/member/profile/${id}`);
};

export const CheckCompanyExist = (data) => {
  return API.post("/check-company-exists", {
    email: data.email,
    password: data.password,
  });
};
