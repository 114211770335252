import React, { useContext, useState } from "react";
import styled from "styled-components";
import { TextField, theme, auth, Alert, firebase, i18n } from "@project/shared";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as yup from "yup";
import { Form, Button } from "antd";
import Link from "next/link";
// import { FacebookIcon } from "../../../assets/icons";
import Google from "../../../assets/google.svg";
import { useRouter } from "next/router";
import {
  AddMember,
  GetUserInfo,
  ChangeSBEmail,
} from "../../../services/sbMembers";
import { useMutation } from "react-query";
import { AuthContext } from "../../../utils";
import { CheckCompanyExist } from "../../../services/user";

interface LoginProps {
  isSignUp?: boolean;
}
const MainContainer = styled.div`
  overflow: hidden;
  /* background: ${theme.backgroundColor}; */
  padding: ${(props: LoginProps) =>
    props.isSignUp ? "0px" : "29px 0px 0px 0px"};

  @media (max-width: 768px) {
    padding: 0px;
    height: ${(props: LoginProps) =>
      props.isSignUp ? "fit-content" : "92.8vh"};
    background: ${theme.base};
    margin-top: ${(props: LoginProps) => (props.isSignUp ? "14px" : "0px")};
    padding-top: ${(props: LoginProps) => (props.isSignUp ? "0px" : "22px")};
  }
`;

const LoginContainer = styled.div`
  max-width: 1000px;
  background: ${theme.base};
  width: 100%;
  max-height: ${(props: LoginProps) => (props.isSignUp ? "100vh" : "700px")};
  margin: 0 auto;
`;
const LoginWrapper = styled.div`
  max-width: 480px;
  width: 100%;
  margin: 0 auto;
  /* height: 100vh; */
  display: flex;
  padding: ${(props: LoginProps) => (props.isSignUp ? "0px" : "29px 0px")};

  flex-direction: column;
  @media (max-width: 768px) {
    padding: ${(props: LoginProps) => (props.isSignUp ? "0px" : "0px 20px")};
    justify-content: flex-start;
  }
  h3 {
    ${theme.typography.typographyXL};
    color: ${theme.planeText};
    margin-bottom: 30px;
  }
  & a {
    color: ${theme.primary};
  }
  & a:hover {
    color: ${theme.link};
  }
`;

const LoginInputWrapper = styled.div`
  max-width: 600px;
  width: 100%;
  margin: 0 auto;
  @media (max-width: 768px) {
    max-width: 100%;
  }
`;
const StyledButton = styled(Button)`
  max-width: 280px;
  width: 100%;
  height: 48px;
  background: ${theme.blueButton};
  border-radius: 4px;
  display: block;
  margin: 0 auto;
  ${theme.typography.typographyLarge};
`;
const ForgotWrapper = styled.div`
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
  @media (max-width: 768px) {
    margin-top: 26px;
    margin-bottom: 38px;
  }
`;
const StyledA = styled.a`
  ${theme.typography.typographyLarge};
  color: ${theme.blueButton};
  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 16px;
  }
`;
const SocialIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  & button {
    justify-content: center;
  }
  @media (max-width: 365px) and (min-width: 331px) {
    & button {
      flex: 1;
    }
    & button:first-child {
      margin-right: 7px;
    }
    & button:last-child {
      margin-left: 7px;
    }
  }
  @media (max-width: 330px) {
    flex-direction: column;
    & button:last-child {
      margin-top: 8px;
    }
  }
`;
const SocialButton = styled(Button)`
  display: flex;
  align-items: center;
  max-width: 220px;
  width: 100%;
  height: 48px;
  border: 1px solid #e8e8e8;
  border-radius: 2px;
  color: ${theme.planeText};
  span {
    margin-left: 10px;
    ${theme.typography.typographyMedium};
    @media (max-width: 500px) {
      font-size: 12px;
    }
    @media (max-width: 365px) {
      font-size: 10px;
    }
  }
  svg {
    width: 19px;
    height: 19px;
  }
  @media (max-width: 768px) {
    max-width: 200px;
  }
  @media (max-width: 500px) {
    max-width: 160px;
    padding: 6.4px 8px;
    span {
      margin-left: 6px;
    }

    svg {
      width: 17px;
      height: 17px;
    }
  }

  @media (max-width: 365px) {
    max-width: unset;
  }
`;
const StyledSignUpText = styled.p`
  font-family: Helvetica, Arial, Verdana, Tahoma, sans-serif;
  ${theme.typography.typographyMedium};
  color: ${theme.planeText};
  margin-top: 40px;
  margin-bottom: 30px;
  @media (max-width: 768px) {
    margin-top: 34px;
    margin-bottom: 27px;
  }
`;
const Term = styled.p`
  ${theme.typography.typographySmall};
  font-family: Helvetica, Arial, Verdana, Tahoma, sans-serif;
  color: ${theme.planeText};
  margin-bottom: 25px;
  white-space: break-spaces;
`;

const ForgetPasswordInfo = styled.p`
  margin-bottom: 30px;
`;

const Login = ({ isSignUp }: LoginProps) => {
  const { t } = useTranslation();
  const router = useRouter();
  const [loading, setLoading] = useState(false);
  const { setUser } = useContext(AuthContext);
  const mutation = useMutation(AddMember, {
    onSuccess: () => {
      sendVerificationEmail();
    },
    onError: () => {
      firebase.auth().currentUser.delete();
      firebase.auth().signOut();
    },
  });

  const companyMutation = useMutation(CheckCompanyExist, {
    onSuccess: () => {
      handleLoginFormSubmit();
    },
    onError: (error: any) => {
      if (error.data.error.message == "EMAIL_NOT_FOUND") {
        Alert({
          type: "error",
          message: t("Error Occured"),
          description: t("Email or password doesn't match"),
        });
      } else if (error.data.error.message == "INVALID_PASSWORD") {
        Alert({
          type: "error",
          message: t("Error Occured"),
          description: t("Email or password doesn't match"),
        });
      } else if (
        error.data.error.message ==
        "TOO_MANY_ATTEMPTS_TRY_LATER : Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later."
      ) {
        Alert({
          type: "warning",
          message: t(
            "The maximum number of password attempts has been reached. Please try again in a few minutes."
          ),
        });
      } else {
        Alert({
          type: "error",
          message: t("Error Occured"),
          description: t(error.data.error.message),
        });
      }
    },
  });

  const mutationSocial = useMutation(AddMember, {
    onSuccess: async () => {
      router.push("/");
    },
    onError: () => {
      router.push("/");
    },
  });

  const sendVerificationEmail = async () => {
    const user = firebase.auth().currentUser;
    const confirmationURL = window.location.origin;
    firebase.auth().languageCode = i18n.language.startsWith("ja") ? "ja" : "en";
    await user.sendEmailVerification({ url: confirmationURL }).then(() => {
      Alert({
        type: "success",
        message: t("Verification Email Sent"),
      });
      router.push("/email-verification");
    });
  };
  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .required(t("Please enter"))
      .email(t("Email format is incorrect. ")),
    password: yup
      .string()
      .required(t("Please enter"))
      .min(6, t("minLengthError", { length: 6 })),
  });
  const handleLoginFormSubmit = async () => {
    setLoading(true);
    try {
      await auth().signInWithEmailAndPassword(
        formik.values.email,
        formik.values.password
      );

      const idToken = await auth().currentUser.getIdTokenResult();
      if (idToken.claims?.isSbMember !== true) {
        Alert({
          type: "error",
          message: t(
            "This account is not registered as sb member. Please register from member registration page."
          ),
        });
        await firebase.auth().signOut();
        setUser(null);
        return;
      }
      checkIfEmailChanged(firebase.auth().currentUser.uid);
      router.push("/");
    } catch (error) {
      const errorCode = error.code;

      if (
        errorCode === "auth/user-not-found" ||
        errorCode === "auth/wrong-password"
      ) {
        Alert({
          type: "error",
          message: t("Error occured"),
          description: t("Email or password doesn't match"),
        });
      } else if (errorCode === "auth/too-many-requests") {
        Alert({
          type: "warning",
          message: t(
            "The maximum number of password attempts has been reached. Please try again in a few minutes."
          ),
        });
      } else {
        Alert({
          type: "error",
          message: t("Something went wrong"),
          description: t("Please try again"),
        });
      }
    }
    setLoading(false);
  };
  const handleSignUpFormSubmit = async () => {
    setLoading(true);
    try {
      await auth().createUserWithEmailAndPassword(
        formik.values.email,
        formik.values.password
      );
      const user = auth().currentUser;
      firebase.auth().languageCode = i18n.language.startsWith("ja")
        ? "ja"
        : "en";

      if (user) {
        const trackingScript = document.createElement("script");
        trackingScript.src = `https://r.moshimo.com/af/r/result.js?p_id=1926&pc_id=3824&m_v=${user.uid}`;
        trackingScript.id = "msmaf";
        document.body.appendChild(trackingScript);
        mutation.mutate({ email: formik.values.email });
      }
    } catch (error) {
      if (error?.message) {
        if (error?.code === "auth/email-already-in-use") {
          Alert({
            type: "error",
            message: t("Error Occured"),
            description: t(
              "The email address is already in use by another account, please try logging in or resetting your password."
            ),
          });
        } else {
          Alert({
            type: "error",
            message: t("Error Occured"),
            description: t(error?.message),
          });
        }
      }
    }
    setLoading(false);
  };
  const handleLogin = () => {
    companyMutation.mutate({
      email: formik.values.email,
      password: formik.values.password,
    });
  };

  const { mutate: checkIfEmailChanged } = useMutation(GetUserInfo, {
    onSuccess: (response) => {
      if (
        response.data.email &&
        response.data.email != firebase.auth().currentUser.email
      ) {
        ChangeEmail();
      }
    },
  });

  const { mutate: ChangeEmail } = useMutation("change-email", () =>
    ChangeSBEmail(firebase.auth().currentUser.email)
  );

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema,
    onSubmit: isSignUp ? handleSignUpFormSubmit : handleLogin,
  });

  const handleAuthSuccess = async (response) => {
    if (response?.user?.email) {
      const trackingScript = document.createElement("script");
      trackingScript.src = `https://r.moshimo.com/af/r/result.js?p_id=1926&pc_id=3824&m_v=${response.user.uid}`;
      trackingScript.id = "msmaf";
      document.body.appendChild(trackingScript);
      mutationSocial.mutate({ email: response.user.email });
    } else {
      if (response?.user?.email == "" || response?.user?.email === null) {
        Alert({
          type: "error",
          message: t(
            "Failed to get the email. Either selected account doesn't have an email or not authorized to share. "
          ),
        });
        await firebase.auth().currentUser.delete();
        await firebase.auth().signOut();
        router.push("/signup");
      }
    }
  };
  const handleAuthError = (err) => {
    if (err.code == "auth/user-disabled") {
      Alert({
        type: "error",
        message: t("Error Occured"),
        description: t("USER_DISABLED"),
      });
    }
    if (err.code == "auth/account-exists-with-different-credential") {
      Alert({
        type: "error",
        message: t("Error Occured"),
        description: t("This user is registered from different provider"),
      });
    }
  };

  const snsLoginHandler = async (sns: string) => {
    let provider: firebase.auth.AuthProvider;
    switch (sns) {
      case "facebook":
        provider = new firebase.auth.FacebookAuthProvider();

        break;
      case "google":
        provider = new firebase.auth.GoogleAuthProvider();
        break;
      default:
        break;
    }
    await firebase
      .auth()
      .signInWithPopup(provider)
      .then((user) => {
        handleAuthSuccess(user);
      })
      .catch(handleAuthError);
  };

  return (
    <MainContainer isSignUp={isSignUp}>
      <LoginContainer isSignUp={isSignUp}>
        <LoginWrapper isSignUp={isSignUp}>
          {!isSignUp && <h3>{t("Login")}</h3>}
          <LoginInputWrapper>
            <Form onFinish={formik.handleSubmit}>
              <TextField
                name="email"
                width="100%"
                type="email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.email && formik.errors.email}
                value={formik.values.email}
                label={t("Email Address")}
              />
              <TextField
                name="password"
                width="100%"
                type="password"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.password && formik.errors.password}
                value={formik.values.password}
                label={t("Password")}
              />
              {!isSignUp && (
                <ForgetPasswordInfo>
                  {t(
                    `If you are logging in for the first time after renewal, please go to "Forgot your password?" to reset your password.`
                  )}
                </ForgetPasswordInfo>
              )}
              <StyledButton loading={loading} htmlType="submit" type="primary">
                {isSignUp ? t("Sign up") : t("Login")}
              </StyledButton>
            </Form>
            <ForgotWrapper>
              {!isSignUp && (
                <Link href={"/forgot-password"}>
                  <StyledA>{t("Forgot password?")}</StyledA>
                </Link>
              )}
            </ForgotWrapper>
            <SocialIconWrapper>
              <SocialButton
                onClick={() => snsLoginHandler("google")}
                size={"large"}
                icon={<Google />}
              >
                {isSignUp ? t("Signup with Google") : t("Login with Google")}
              </SocialButton>
              {/* <SocialButton
                onClick={() => snsLoginHandler("facebook")}
                size={"large"}
                icon={<FacebookIcon />}
              >
                {isSignUp
                  ? t("Signup with Facebook")
                  : t("Login with Facebook")}
              </SocialButton> */}
            </SocialIconWrapper>
            <StyledSignUpText>
              {isSignUp
                ? t("Already have a account ? ")
                : t("Don’t have an account ? ")}
              <Link href={!isSignUp ? "/signup" : "/login"}>
                <a>{isSignUp ? t("Sign In") : t("Sign up")}</a>
              </Link>
            </StyledSignUpText>

            {i18n.language !== "ja" ? (
              <Term>
                {t(
                  "By clicking the above buttons, you are creating a sidebizz account, and you agree to sidebizz "
                )}
                <Link href="/terms" passHref>
                  <a target="_blank">{t("Terms of Use")}</a>
                </Link>
                {t(" and ")}
                <Link href="/privacy" passHref>
                  <a target="_blank">{t("Privacy Policy")}</a>
                </Link>
              </Term>
            ) : (
              <Term>
                <Link href="/terms" passHref>
                  <a target="_blank">{t("Terms of Use")}</a>
                </Link>
                {t(" and ")}
                <Link href="/privacy" passHref>
                  <a target="_blank">{t("Privacy Policy")}</a>
                </Link>
                にご同意の上会員登録してください。
              </Term>
            )}
          </LoginInputWrapper>
        </LoginWrapper>
      </LoginContainer>
    </MainContainer>
  );
};

export { Login };
