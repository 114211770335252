import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "./utils/AuthContext";
import Router from "next/router";
import { Spin } from "antd";
import styled from "styled-components";
const Spinner = styled(Spin)`
  min-height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const RestrictedRoute = (AuthComponent) => {
  function RestrictedComponent({ children }) {
    const { authenticated, loading, user } = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
      if (authenticated == null) {
        return;
      }

      if (authenticated) {
        if (
          user &&
          !user?.emailVerified &&
          (Router.pathname == "/login" || Router.pathname === "/signup")
        ) {
          setIsLoading(false);
          return;
        }
        if (user?.emailVerified) {
          setIsLoading(false);
          Router.push("/");
          return;
        }

        Router.push("/email-verification");
      }
      setIsLoading(false);
    }, [authenticated, loading]);

    if (loading || isLoading) {
      return (
        <Spinner>
          <Spin size="large" />
        </Spinner>
      );
    }
    return <>{children}</>;
  }

  return class Higher extends React.Component {
    render() {
      return (
        <RestrictedComponent>
          <AuthComponent {...this.props} />
        </RestrictedComponent>
      );
    }
  };
};

export default RestrictedRoute;
